@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&display=swap');

.App {
  text-align: center;
}

@keyframes aurora {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  background: linear-gradient(45deg, #FFD700, #00CED1, #20B2AA, #FFD700);
  background-size: 400% 400%;
  animation: aurora 15s ease infinite;
  margin: 0;
  padding: 0;
  font-family: 'Cinzel', serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  box-sizing: border-box;
}

header {
  background: linear-gradient(45deg, #FFD700, #00CED1, #20B2AA, #FFD700);
  background-size: 400% 400%;
  animation: aurora 15s ease infinite;
  padding: 0.5rem;
  font-family: 'Cinzel', serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

footer {
  background-color: #FFD700;
  padding: 0.5rem;
  font-family: 'Cinzel', serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(45deg, #FFD700, #00CED1, #20B2AA, #FFD700);
  background-size: 400% 400%;
  animation: aurora 15s ease infinite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: 'Cinzel', serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive font sizes and layout */
html {
  font-size: 16px;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

h1 {
  font-size: 2rem;
  margin: 0.5rem 0;
}

h2 {
  font-size: 1.5rem;
  margin: 0.4rem 0;
}

p {
  font-size: 1rem;
  margin: 0.3rem 0;
}

/* Tablet styles */
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
  
  header, footer {
    padding: 0.4rem;
  }
  
  main {
    padding: 0.8rem;
  }
}

/* Mobile styles */
@media (max-width: 375px) {
  html {
    font-size: 12px;
  }
  
  header, footer {
    padding: 0.3rem;
  }
  
  main {
    padding: 0.6rem;
  }
  
  h1 {
    font-size: 1.8rem;
  }
  
  h2 {
    font-size: 1.3rem;
  }
}
